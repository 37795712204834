import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const group = d => {
    let groups = [];
    let prevGroup = '';
    d.map(r => {
        const s = r.node.fields.slug;
        let t = s.substring(1);
        let group = t.substring(0, t.indexOf('/'));
        if (group !== prevGroup) {
            groups.push({
                id: `group-${groups.length}`,
                title: group.split('-').join(' ')
            });
            prevGroup = group;
        }
        groups.push({
            id: r.node.id,
            slug: s,
            title: r.node.frontmatter.title
        });
    });
    return groups;
}

const giveBullet = slug => {
    if (slug) {
        return undefined;
    } else {
        return {listStyleType: `none`};
    }
}

const RecipePage = ({ data }) => (
    <Layout>
        <SEO title="Sutphen Family Recipes" />
        <h1>Recipes</h1>
        <h2>Index</h2>
        
        <ul>
        {group(data.allMarkdownRemark.edges).map(recipe => (
            <li key={recipe.id} style={giveBullet(recipe.slug)}>
                {!recipe.slug && 
                    <strong style={{textTransform: `capitalize`}}>
                        <p>{recipe.title}</p>
                    </strong>
                }
                {recipe.slug && 
                    <Link to={recipe.slug}>{recipe.title}</Link>
                }
            </li>
        ))}
        </ul>
    </Layout>
)

export default RecipePage

export const pageQuery = graphql`
    query IndexQuery {
        allMarkdownRemark(sort: {fields: fields___slug}) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`